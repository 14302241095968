import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/affilate_mono_repo_nyaskosnoren/gatsby/src/components/mdxLayout.js";
import { graphql, Link } from "gatsby";
import ProductsDisplay from "../../components/productDisplay.js";
import CategoriesLinks from "../../components/categoriesLinks.js";
export const query = graphql`
  query {
    allMongodbStoreProducts(sort: {fields: popularity_score, order: DESC}, filter: {derived_properties: {color: { in: "röda" }}}) {
      edges {
        node {
          	brand
			full_name
			id
			local_image_name
			model
			original_prices
			popularity_score
			prices
			product_id
			similair_products
			show_on_pages
			sub_product_type
			store
			url
			engine
			last_updated_date
			derived_properties {
				color
				elastic
				form
				waxed
				the_length
			}
			
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "röda-skosnören"
    }}>{`Röda Skosnören`}</h1>
    <p>{`Om det så är ljusröda eller mörka vinröda skosnören så kommer de garanterat ge nytt liv åt dina skor. Passar utmärkt till ett par sneakers, oavsett färg. Eller så kanske du redan har ett par gamla röda skosnören som börjar bli lite trötta, trasiga och har tappat färgen. Då hittar du dina nya här. Använd gärna filtret nedan för att hitta rätt längd på dina nya röda skosnören.`}</p>
    <CategoriesLinks mdxType="CategoriesLinks"></CategoriesLinks>
    <ProductsDisplay data={props.data.allMongodbStoreProducts} location={props.location} mdxType="ProductsDisplay"></ProductsDisplay>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      